import React from "react"
import HomeLayout from "../layouts/HomeLayout"
import Footer from "../components/Footer"
import { Grid, Paper } from "@material-ui/core"
import { Link } from "gatsby"

const AboutUs = () => {
  return (
    <React.Fragment>
      <div style={{ height: "14vh", padding: 20 }}>
        <p
          style={{
            fontSize: 40,
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          About
        </p>
      </div>
      <Grid
        align="center"
        style={{ position: "absolute", overflowY: "auto", overflowX: "hidden" }}
      >
        <img
          style={{ marginTop: 30 }}
          src={require("../../assets/Logo/logo.png")}
          width={"40%"}
        />
        <div style={{ width: "60%" }}>
          {" "}
          <p
            style={{
              fontSize: 25,
              fontWeight: "bold",
              textAlign: "left",
              paddingTop: 20,
            }}
          >
            Who ?
          </p>
          <p
            style={{
              fontSize: 19,
              textAlign: "left",
              paddingTop: 20,
            }}
          >
            We extensively focuses on research and technology as core areas to
            shape its growth and improving client satisfaction. It has built a
            robust infrastructure to meet growing requirements of its clients
            and to over time bound delivery of quality services. We are
            entrepreneurs; all inspired by a vision of a world where we create a
            one stop financial hub & we’re a door you should knock when you
            think of Finance! With a robust presence across Equity & commodity
            broking, Mutual Fund Distribution, Insurance Broking, Management
            Consulting, Investment Banking, Infrastructure Consulting.
          </p>
        </div>
      </Grid>
      <Footer />
    </React.Fragment>
  )
}

export default AboutUs
